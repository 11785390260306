import { useContext, useEffect, useState } from "react";
import { getProducts, updateProduct } from "../../../services/Product";
import { PermitsContext } from "../../../services/Permits";
import { alertOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Filters from "./Filters";
import TableProduct from "./TableProduct";
import Loader from "../../../components/loader/Loader";
import ModalCompatible from "./ModalCompatible";
import Accessories from "./Accessories";

const Users = () => {
  const { dataSession, permits } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [updatePagination, setUpdatePagination] = useState(false);
  const [filterPagination, setFilterPagination] = useState({ sort: "DESC"});
  const [page, setPage] = useState(1);

  const [stateOptions] = useState([
    { label: "Active", value: "active" },
    { label: "Deactivate", value: "inactivated" }
  ]);

  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState("vehicle-glasses");

  const getData = (pge, filterPagination) => {
    setLoader(true);
    getProducts(pge, 10, filterPagination).then(res => {
      if (res.status === 200 && res.data.products.length > 0) {
        const notDeletedProducts = res.data.products.filter(product => !product.deleted);
        setProducts(notDeletedProducts);
        setTotalCount(res.data.count);
        setUpdatePagination(true);
        setLoader(false);
      } else {
        setProducts([]);
        setTotalCount(0);
        setLoader(false);
      }
    });
  };

  const handleFilters = (wordFilter, type, value) => {
    if (type === "status") {
      let filterObject = {
        sort: "DESC"
      };
      for (const key in value) {
        if (Object.hasOwnProperty.call(value, key)) {
          if (value[key] !== "" && value[key] !== "default") filterObject[key] = value[key];
        }
      }
      getData(1, filterObject);
      setFilterPagination(filterObject);
    }

  };

  const deactivateProduct = (sts) => {
    if (sts) {
      const productModel = {
        _id: showModal.element.data._id,
        status: showModal.element.status ? "active" : "inactivated",
        name: showModal.element.data.name,
        brand: showModal.element.data.brand,
        nags: showModal.element.data.nags,
        model: showModal.element.data.model,
        year: showModal.element.data.year,
        price: showModal.element.data.price,
        serviceTime: showModal.element.data.serviceTime,
        barcode2: showModal.element.data.barcode2,
        glassType: showModal.element.data.glassType,
        type_car: showModal.element.data.type_car,
        totalQuantity: showModal.element.data.totalQuantity,
        by: showModal.element.data.by ? { _id: showModal.element.data.by._id } : { _id: dataSession._id },
        vendors:
                    showModal.element.data.vendors.map((vendorElement) => {
                      return (
                        { _id: vendorElement._id }
                      );
                    })
        ,
        features: showModal.element.data.features,
        description: showModal.element.data.description,
        productImages: showModal.element.data.productImages,
        productSpecification: showModal.element.data.productSpecification ?
          { _id: showModal.element.data.productSpecification._id } : {}
      };
      setLoader(true);
      updateProduct(productModel._id, productModel).then(res => {
        if (res.status === 200) {
          getData(page, filterPagination);
          toast.success("Product updated successfully", alertOptions);
        } else {
          setLoader(false);
          toast.warning(res.response.data.message, alertOptions);
        }
        setShowModal({...showModal, status: false, element: null});
      }).catch(error => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
    }
  };

  const changePage = (pge) => {
    new Promise((resolve) => {
      getData(pge, filterPagination);
      resolve();
    }).then(() => {
      setPage(pge);
    });
  };

  useEffect(() => {
    getData(1, filterPagination);
  }, []);

  return (
    <>
      { loader && <Loader /> }
      <div className="min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 py-8">

          <h1 className="text-3xl font-semibold mb-6">Products</h1>

          <div className="bg-white shadow rounded-lg mb-6">
            <div className="flex">
              <button
                onClick={() => setActiveTab("vehicle-glasses")}
                className={`flex-1 px-4 py-3 text-center font-medium text-sm focus:outline-none ${
                  activeTab === "vehicle-glasses"
                    ? "text-red-600 border-b-2 border-red-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                NAGS
              </button>

              <button
                onClick={() => setActiveTab("accessories")}
                className={`flex-1 px-4 py-3 text-center font-medium text-sm focus:outline-none ${
                  activeTab === "accessories"
                    ? "text-red-600 border-b-2 border-red-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Accessories
              </button>
            </div>
          </div>

          { activeTab === 'accessories'
            ?
            (
              <Accessories />
            )
            : (
              <div>
                <div className="pt-3">
                  <Filters
                    enableFilters={permits['PRODUCTS']['FILTER']}
                    enableAdd={permits['PRODUCTS']['ADD']}
                    enableHistory={permits['PRODUCTS']['HISTORY']}
                    statusOptions={stateOptions}
                    onFilters={handleFilters}
                  />
                </div>

                <div className="mt-[40px]">
                  <TableProduct
                    enableEdit={permits['PRODUCTS']['EDIT']}
                    enableDeactivate={permits['PRODUCTS']['DEACTIVATE']}
                    currntPg={page}
                    products={products}
                    totalCount={totalCount}
                    onPage={(pg) => changePage(pg)}
                    onStatus={(productElement, sts) => {
                      setShowModal({...showModal, status: true, element: { status: sts, data: productElement }});
                    }}
                    onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
                    updatePagination={updatePagination}
                    onResponseUpdatePagination={(resp) => setUpdatePagination(resp)}
                  />
                </div>
              </div>
            )}

          { showModal.status ?
            <Modal
              title={`¿Are you sure you want to ${ showModal.element.status ? "activate" : "deactivate"} the product?`}
              onClose={() => setShowModal({...showModal, status: false, element: null})}
              onResponse={deactivateProduct}
            />
            :
            null
          }

          {
            showCompatible.status ?
              <ModalCompatible
                products={showCompatible.element}
                onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
              />
              : null
          }
        </div>
      </div>
    </>
  );
};

export default Users;